<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>{{ $t("general.manage_email_templates") }}</li>
            </ul>
          </nav>
        </div>
        <template v-if="errorMessages">
          <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </template>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <h4><i class="uil-mailbox"></i>{{ $t("mail.management") }}</h4>
            <hr />
            <form action="">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 p-0">
                    <ul class="uk-tab-right uk-tab sidemenu"
                      uk-switcher="connect: #settings-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                      <li :class="item.selected" v-for="(item, index) in templates" @click="activeTemplate = index">
                        <a href="#"><i class="uil-brightness-empty"></i>
                          <span>{{
                            $t(item.key_name).replace("{HOUR}", item.hours)
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-8">
                    <ul class="uk-switcher" id="settings-tab">
                      <li v-for="(item, index) in templates">
                        <div class="col-md-12">
                          <button class="
                              btn btn-secondary
                              uk-button uk-button-default
                              mb-3
                            " type="button">
                            <i class="uil-plus-circle"></i>
                            {{ $t("mail.add_code") }}
                          </button>
                          <div class="uk-width-large" uk-dropdown>
                            <div class="uk-dropdown-grid uk-child-width-1-2@m" uk-grid>
                              <div>
                                <ul class="uk-nav uk-dropdown-nav">
                                  <template v-for="(code, i) in getCodeByIndex(item.id)">
                                    <template v-if="i < codeSlice(item.id)">
                                      <li class="uk-active">
                                        <a href="#">{{ $t(code.key_name) }}</a>
                                      </li>
                                      <li>
                                        <b>{{ code.code }}</b>
                                        <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                          {{ $t("general.copy") }}</a>
                                      </li>
                                      <li class="uk-nav-divider"></li>
                                    </template>
                                  </template>
                                </ul>
                              </div>
                              <div>
                                <ul class="uk-nav uk-dropdown-nav">
                                  <template v-for="(code, i) in getCodeByIndex(item.id)">
                                    <template v-if="i >= codeSlice(item.id)">
                                      <li class="uk-active">
                                        <a href="#">{{ code.name }}</a>
                                      </li>
                                      <li>
                                        <b>{{ code.code }}</b>
                                        <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                          {{ $t("general.copy") }}</a>
                                      </li>
                                      <li class="uk-nav-divider"></li>
                                    </template>
                                  </template>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <html-area v-if="activeTemplate == index" :model="item" :company="company"
                            @update="(value) => updateCodeByIndex(index, value)"></html-area>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-primary small" type="button" @click="mailSubmit">
                  <i class="icon-feather-arrow-right"></i>
                  {{ $t("general.save") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import HtmlArea from "@/view/company/Mail/Html";
import store from "@/core/services/index";

import mail_module, {
  MODULE_NAME as MAIL_MODULE_NAME,
  ITEM as MAIL_ITEM,
  BASE_URL as MAIL_BASE_URL,
  CODES_BASE_URL as MAIL_CODES_BASE_URL,
  GET_ITEM as MAIL_GET_ITEM,
  ERROR as MAIL_ERROR,
  UPDATE_ITEM as MAIL_UPDATE_ITEM,
  GET_CODES as MAIL_GET_CODES,
  CODES as MAIL_CODES,
} from "@/core/services/store/mail_template.module";
import company_module, {
  MODULE_NAME as COMPANY_MODULE_NAME,
  ITEM as COMPANY_ITEM,
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEM as COMPANY_GET_ITEM,
} from "@/core/services/store/company.module";

export default {
  name: "MailTemplate",
  components: { DataTable, HtmlArea },
  data() {
    return {
      activeTemplate: 0,
      successMessage: null,
      errorMessages: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MAIL_MODULE_NAME, mail_module);
    registerStoreModule(COMPANY_MODULE_NAME, company_module);
  },
  computed: {
    templates: {
      get() {
        return store.getters[MAIL_MODULE_NAME + "/" + MAIL_ITEM];
      },
      set(val) { },
    },
    codes: {
      get() {
        return store.getters[MAIL_MODULE_NAME + "/" + MAIL_CODES];
      },
      set(val) { },
    },
    company: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_ITEM];
      },
      set(val) { },
    },
    errors: {
      get() {
        return store.getters[MAIL_MODULE_NAME + "/" + MAIL_ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    updateCodeByIndex(index, newCode) {
      //validate the link should be exist if it is required
      if (
        this.templates[index].is_linked &&
        (!newCode.includes("{SLINK}") || !newCode.includes("<!--BUTTON-->"))
      ) {
        if (this.errorMessages.length == 0)
          this.errorMessages.push(
            this.$t("validation.required_field").replace(":NAME", "{SLINK}")
          );
        return false;
      }
      //update template code with new one
      this.templates[index].html = newCode;
    },
    getCodeByIndex(value) {
      if (this.codes.length == undefined) return [];
      var index = this.codes.findIndex((c) => c.mail_template_id == value);
      return this.codes[index].data;
    },
    codeSlice(value) {
      if (this.codes.length == undefined) return [];
      var index = this.codes.findIndex((c) => c.mail_template_id == value);
      return Math.ceil(this.codes[index].data.length / 2);
    },
    mailSubmit() {
      this.$store
        .dispatch(MAIL_MODULE_NAME + "/" + MAIL_UPDATE_ITEM, {
          url: MAIL_BASE_URL,
          contents: { data: this.templates },
        })
        .then(() => {
          if (!this.errors) {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.successMessage = this.$t("general.successfully_updated")
          }
        });
    },
    getMailItems() {
      this.$store.dispatch(MAIL_MODULE_NAME + "/" + MAIL_GET_ITEM, {
        url: MAIL_BASE_URL,
      });
      this.$store.dispatch(MAIL_MODULE_NAME + "/" + MAIL_GET_CODES, {
        url: MAIL_CODES_BASE_URL,
      });
    },
    getCompanyItems() {
      this.$store.dispatch(COMPANY_MODULE_NAME + "/" + COMPANY_GET_ITEM, {
        url: COMPANY_BASE_URL + "/self",
      });
    },
    copyCode(code) {
      const el = document.createElement("textarea");
      el.value = code;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getMailItems();
    this.getCompanyItems();
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
.uk-tab li.uk-active a {
  border-color: #eceff0;
}

.uk-tab-right::before {
  display: none;
}

.sidemenu li a {
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}
</style>